exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-ai-guide-js": () => import("./../../../src/pages/aiGuide.js" /* webpackChunkName: "component---src-pages-ai-guide-js" */),
  "component---src-pages-ai-index-js": () => import("./../../../src/pages/aiIndex.js" /* webpackChunkName: "component---src-pages-ai-index-js" */),
  "component---src-pages-benchmark-q-4-js": () => import("./../../../src/pages/benchmarkQ4.js" /* webpackChunkName: "component---src-pages-benchmark-q-4-js" */),
  "component---src-pages-benchmarks-js": () => import("./../../../src/pages/benchmarks.js" /* webpackChunkName: "component---src-pages-benchmarks-js" */),
  "component---src-pages-editor-js": () => import("./../../../src/pages/editor.js" /* webpackChunkName: "component---src-pages-editor-js" */),
  "component---src-pages-examples-js": () => import("./../../../src/pages/examples.js" /* webpackChunkName: "component---src-pages-examples-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-insight-js": () => import("./../../../src/pages/insight.js" /* webpackChunkName: "component---src-pages-insight-js" */),
  "component---src-pages-news-js": () => import("./../../../src/pages/news.js" /* webpackChunkName: "component---src-pages-news-js" */),
  "component---src-pages-plan-agent-js": () => import("./../../../src/pages/planAgent.js" /* webpackChunkName: "component---src-pages-plan-agent-js" */),
  "component---src-pages-pricing-index-js": () => import("./../../../src/pages/pricingIndex.js" /* webpackChunkName: "component---src-pages-pricing-index-js" */),
  "component---src-pages-resources-js": () => import("./../../../src/pages/resources.js" /* webpackChunkName: "component---src-pages-resources-js" */),
  "component---src-pages-tactic-js": () => import("./../../../src/pages/tactic.js" /* webpackChunkName: "component---src-pages-tactic-js" */),
  "component---src-templates-insight-js": () => import("./../../../src/templates/Insight.js" /* webpackChunkName: "component---src-templates-insight-js" */),
  "component---src-templates-news-js": () => import("./../../../src/templates/News.js" /* webpackChunkName: "component---src-templates-news-js" */),
  "component---src-templates-tactic-js": () => import("./../../../src/templates/Tactic.js" /* webpackChunkName: "component---src-templates-tactic-js" */)
}

