import "./src/styles/global.css"
import React from 'react';
import { Auth0Provider } from '@auth0/auth0-react';
import { navigate } from 'gatsby';

const onRedirectCallback = (appState) => {
    // Use Gatsby's navigate method to replace the url
    console.log("onRedirectCallback");
    console.log(appState);

    navigate(appState?.returnTo || '/', { replace: true });
};

export const wrapRootElement = ({ element }) => {
    console.log("process.env.REACT_APP_AUTH0_DOMAIN",process.env.REACT_APP_AUTH0_DOMAIN);
    console.log("process.env.REACT_APP_AUTH0_CLIENT_ID",process.env.REACT_APP_AUTH0_CLIENT_ID);
    return (
        <Auth0Provider
            domain={process.env.REACT_APP_AUTH0_DOMAIN}
            clientId={process.env.REACT_APP_AUTH0_CLIENT_ID}
            redirectUri={window.location.origin}
            onRedirectCallback={onRedirectCallback}
        >
            {element}
        </Auth0Provider>
    );
};